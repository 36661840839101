.audit_parent {
	display: flex;
	align-items: flex-start;
	width: 100%;
	height: 100%;
	background-color: rgb(228, 228, 228);
}

.left_box {
	margin: 20px;
	padding: 20px;
	background-color: white;
	width: 20%;
}

.audit_options_container{
	display: flex;
	justify-content: space-around;
}

.breadcrumb_container {
	font-size: 20px !important;
    font-weight: 100 !important;
	color: black !important;
    width: fit-content !important;
    padding-right: 10px;
	margin-left: 20px;
    display: flex;
    margin-bottom: 30px;
    background-color: white;
}

.bc_item {
	font-size: 20px !important;
    font-weight: 100 !important;
	color: black !important;
}

.active_bc_item {
	font-size: 20px !important;
	font-weight: 100 !important;
	color: black !important;
	padding-left: 18px;
	padding-right: 8px;
}

.audit_heading {
	font-size: 28px;
	margin-top: 3px;
	margin-left: 20px;
	text-align: left;
}

.right_box {
	width: 80%;
}

@media only screen and (max-width: 1600px) {
	.left_box {
		width: 23%;
	}
	.right_box {
		width: 77%;
	}
}

@media only screen and (max-width: 1400px) {
	.left_box {
		width: 25%;
		font-size: 16px;
	}
	.right_box {
		width: 75%;
	}
}

@media only screen and (max-width: 1600px) {
	.audit_parent{
		display: flex;
		flex-direction: column;
	}

	.left_box {
		width: auto;
	}

	.right_box {
		width: 100%;
	}

	.butOne{
		margin-right: 10px;
	}
}

