.analytics_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.linechart_container {
    height: 40% !important;
}

.domain_wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.breadcrumb_container {
    font-size: 18px !important;
    font-weight: 100 !important;
    width: fit-content;
    padding-right: 10px;
    display: flex;
    margin-bottom: 10px;
    background-color: white;
    height: 5%;
}

.page {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 95%;
}

.audit_stats {
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 100%;
    width: 72%;
}

.options_container {
    width: 100%;
    display: flex;
    justify-content: right;
}

.domain_stats {
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    width: 28%;
    align-items: center;
    padding: 5px;
}

.audit_stats_top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40%;
}

.toggle_button {
    border: none;
    background-color: #9084dc;
    color: white;
    font-size: 14px;
    padding: 4px;
}

ol {
    margin: 0px !important;
}

.table_view_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    min-height: 320px;
    text-align: left;
}

.table_view_container h2 {
    width: 75% !important;
    text-transform: capitalize !important;
    font-size: 24px !important;
    font-weight: 300 !important;
}

.table_view {
    margin-top: 20px;
    width: 70%;
    height: 50%;
    background-color: white;
    border-color: lightgray;
}

.audit_stats_bot {
    margin-top: 50px;
    overflow-y: scroll;
}

.audit_list_container {
    padding: 5px;
    width: 47%;
    overflow-y: scroll;
}

.audit_list_heading {
    font-size: 22px !important;
    font-weight: 550 !important;
    margin-left: 3px !important;
    margin-bottom: 5px !important;
    width: 30% !important;
    min-width: 120px !important;
    text-transform: none !important;
}

.audit_list_header {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 20px;
    align-items: center;
}

.domain_top {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: 50%;
}

.domain_heading_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.health_heading {
    font-size: 26px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}

.speedometer_container {
    /* margin: 5px; */
    width: 50%;
    min-width: 230px;
    margin-top: 25px;
    display: flex;
    align-items: center;
}

.table_chart_wrapper {
    width: 53%;
}

.page_control {
    padding: 10px;
    display: flex;
    justify-content: center !important;
}

.prev_list_button {
    margin-right: 5%;
    margin-left: 0%;
}

.next_list_button {
    margin-left: 5%;
    margin-right: 0%;
}

.dropdown {
    margin-left: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 5px;
}

.impact_table_container {
    padding: 7px;
    width: 100%;
    min-width: fit-content;
}

.bc_item {
    font-size: 20px !important;
    font-weight: 100 !important;
    color: black !important;
}

.active_bc_item {
    font-size: 20px !important;
    font-weight: 100 !important;
    color: black !important;
    padding-left: 18px;
    padding-right: 8px;
}

/* Here */

.category_container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
}

.filter_container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
}

.filter_wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.label_container {
    display: flex;
    align-items: center;
}

.filter_label {
    font-size: 17px;
}

.category_dropdown {
    padding: 8px;
}

.category_dropdown option {
    padding: 10px;
}

.category_table td {
    padding: 10px;
    text-transform: capitalize;
}

.category_table th {
    padding: 10px;
}

.category_table, .severity_table {
    background-color: white;
    border-color: lightgray;
    width: 100%;
}

@media only screen and (max-width: 1400px) {
    .page {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
    }

    .domain_stats {
        flex-direction: row;
        width: 100%;
        overflow-y: scroll;
        margin-bottom: 20px;
    }

    .domain_stats {
        height: 30%;
        display: none;
    }

    .audit_stats {
        height: 100%;
        width: 100%;
    }
}