.auth_container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img_container {
    width: fit-content;
    display: flex;
    justify-content: center;
}

.img_container img {
    height: 200px;
    width: 200px;
}

.brand_text {
    font-size: 30px;
}

.login_button {
    border: none;
    background-color: #cc5ca4;
    width: 200px;
    padding: 10px;
    border-radius: 5px;
    color: black;
    font-size: 20px;
}

.login_button:hover {
    background-color: #d877b6;
}

.signup_button {
    border: none;
    background-color: #fbba52;
    width: 200px;
    padding: 10px;
    border-radius: 5px;
    color: black;
    font-size: 20px;
    margin: 15px;
}

.signup_button:hover {
    background-color: #ffcc7b;
}