/* .Container{
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    justify-content: space-between;
    margin-right: center;
    display: flex;
} */

.card{
  border-radius: 35px;
}

.cardButton{
  cursor: pointer;
}