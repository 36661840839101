.audit_list_container {
    overflow-y: scroll !important;
    margin-left: 5%;
    width: 90%;
}

.page_control {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
}

.page_control {
    padding: 10px;
    display: flex;
    justify-content: center !important;
}

.prev_list_button {
    margin-right: 5%;
    margin-left: 0%;
}

.next_list_button {
    margin-left: 5%;
    margin-right: 0%;
}

.dropdown {
    margin-left: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 5px;
}

.breadcrumb_container {
	font-size: 20px !important;
    font-weight: 100 !important;
	color: black !important;
    width: fit-content !important;
    padding-right: 10px;
	margin-left: 20px;
    display: flex;
    margin-bottom: 30px;
    background-color: white;
}

.audit_list_container table {
    margin-bottom: 0px !important;
}

.audit_list_button {
    margin-right: 10px;
}