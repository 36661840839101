.sidebar {
    display: flex;
    flex-direction: column;
    width: 15%;
    /* height: fit-content; */
}

.sidebarlogo {
    display: flex;
    flex-direction: column;
    background-color: #FFBC50;
    flex-direction: column;
}

.sidebarcontent {
    flex: 4;
    background-color: #5F2498;
}

nav {
   display: flex;
   flex-direction: column;
}

nav a {
    padding: 1em;
    text-decoration: none;
    color:white;
    font-size:20px;
    width: 100%
}

nav a:hover {
    /* background-color: #FFBC50; */
    text-decoration: none;
    /* color:white; */
    color: black;
}

.sidebar_list {
    list-style: none;
    width: 100%;
    padding: 0;
}

.sidebar_item{
    width: 100%;
    padding-left: 10px;
}

.sidebar_item:hover {
    background-color: #FFBC50;
}

.sidebar_item a {
    display: flex;
}

.sidebar_item a:hover {
    color: black;
    width: 100%;
}