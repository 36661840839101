/* this is to fix some of bootstrap color accessibility issues */
@import "bootstrap/scss/bootstrap";
@import 'react-bootstrap-sidebar-menu/dist/sidebar-menu.scss';

$gray0: #F6F8FF;
$primary: #636983;
$transition: all .2s;
$gray1: #fbfbfb;

body {
  font-family: "Gill Sans", sans-serif;
  // a:not(.btn) {
  //   color: #15D;
  // }
  ol.breadcrumb {
    background-color: white;
  }
  .btn-primary {
    background-color: #006BF0;
    border-color: #006BF0;
  }
  .btn-primary:hover {
    background-color: #005BDA;
  }
  .btn-info {
    background-color: #008298;
    border-color: #008298;
  }
  .btn-info:hover {
    background-color: #007087;
  }
}

/* More bootstrap changes */
#root {
  font-size: 90%;
  
  .btn-danger {
    background-color: #C04550;
    border-color: #C04550;
  }
  .btn-danger:hover {
    background-color: #AA3540;
  }
  .btn-group-xs > .btn, .btn.btn-xs {
    margin: 0.2em;
    padding: .25rem .4rem;
    font-size: .5rem;
    line-height: .5;
    border-radius: .2rem;
  }
  h1 {
    font-size: 2.0rem;
  }
  h2 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
  h3 {
    font-size: 1.1rem;
  }
  table td {
    vertical-align: middle;
    margin: 12px;
    justify-content: space-around;
  }
  caption {
    padding-bottom: 0.50rem;
  }
}

/* Common application styles */
body {
  background-color: #F0F0F0 !important;
}
.bannerTitle {
  text-align: right;
  font-size: 1.2rem;
  font-style: italic;
  padding-right: 1px;
  margin-bottom: 0.2em;
}
h1 {
  text-align: center;
}
h2 {
  text-transform: uppercase;
}
section {
  margin: 1em 0 1.5em 0;
}

main {
  width: 100%; 
  padding: 1.2em; 
  flex: 1; 
  overflow-y: scroll;
  overflow-x: scroll;
}

.code {
  font-family: monospace, monospace;
}

// table.data {
//   width: auto !important;
//   background-color: white;
//   border-radius: 5px;
//   border-collapse: separate;
//   border-spacing: 0;
//   overflow: hidden;
//   box-shadow: 3px 3px 4px #AAA;
//   margin: 0.5em 0 1em 0;
//   border-color: #040505;

  
//   caption {
//     font-size: 120%;
//     color: #040505;
//     caption-side: top;
//   }
//   thead>tr:first-child>th:first-child, tbody>tr:first-child>th:first-child {
//     border-top-left-radius: 10px;
//   }
//   thead>tr:first-child>th:last-child, tbody>tr:first-child>td:last-child {
//     border-top-right-radius: 10px;
//   }
//   thead~tbody>tr:first-child>td:last-child {
//     border-top-right-radius: 0;
//   }
//   td.code {
//     white-space: pre-wrap;
//     font-size: 90%;
//   }
//   >tbody>tr:nth-of-type(2n+1) {
//     background-color: #EDF9FF;
//   }
//   >tbody>tr:hover {
//     background-color: #F0FFFA;
//   }
// }

#root .border {
  padding: 1em;
  border-color: #888 !important;
  border-radius: 0.5em;
}

// table { 
// 	width: auto; 
// 	border-collapse: collapse; 
// 	// margin:50px auto;
// 	}

// /* Zebra striping */
// tr:nth-of-type(odd) { 
// 	background: #eee; 
// 	}

// th { 
// 	// background: #3498db; 
// 	// color: white; 
// 	font-weight: bold; 
// 	text-align: center; 
// 	}

td, th { 
  text-align: center;
  border: 1px solid black;
}