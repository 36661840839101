
.skip_link {
    position: absolute;
    left: -9999px;
    z-index: 999;
    padding: 1em;
    background-color: black;
    color: white;
    opacity: 0;
  }
  .skip_link:focus {
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
      }
  

