.wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.wrapper h1 {
    align-self: flex-start;
    margin-left: 10px;
}

.table_container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.user_table {
    margin: 10px;
    background-color: white;
    width: 80%;
}