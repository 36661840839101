.container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: white;
    width: 100%;
    padding-bottom: 1em;
    padding-top: 1em;
    box-sizing: border-box;
    height: 100px;
    font-family: "Nunito";
    /* padding: 40px; */
    /* transform: translate(-50%, -50%); */
    box-sizing: border-box;
    /* box-shadow: 0 15px 25px rgba(0,0,0,.6); */
    border-radius: 10px;
    /* border: 1px solid black; */
}
.project_box {
    margin-top: 15px;
}

.piechart{
    font-family: "Nunito"
}

.pieDesc{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.breadcrumb_container {
	font-size: 20px !important;
    font-weight: 100 !important;
	color: black !important;
    width: fit-content !important;
    padding-right: 10px;
	margin-left: 20px;
    display: flex;
    margin-bottom: 30px;
    background-color: white;
}

.bc_item {
	font-size: 20px !important;
    font-weight: 100 !important;
	color: black !important;
}

.active_bc_item {
	font-size: 20px !important;
	font-weight: 100 !important;
	color: black !important;
	padding-left: 18px;
	padding-right: 8px;
}

.pieEle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 15px;
}

.colorEle{
    display: box;
    width: 12px;
    height: 12px;
}

.nameEle{
    color: #666;
}

.Card{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: 1px solid grey;
    box-shadow: 0.5px 0.5px 1px grey;
    padding: 10px;
    box-shadow: 0 15px 25px rgba(0,0,0,.6);
    border-radius: 10px;
    box-sizing: border-box;
    font-family: "Nunito"
}

.bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;   
    /* padding: 15px; */
    width: 100%;
    height: 100%;
    margin-top: 10px;
}

.bottom_left {
    width: 45%;
    height: 100%;
}

.bottom_right {
    width: 45%;
}

.project_box {
    flex: 3;
}

.pie_card {
    flex: 3;
}

.projectList {
    text-align: center;
    color: white;
    background-color: #5F2498;
}

@media only screen and (max-width: 720px) {
    .bottom {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .bottom_left, .bottom_right {
        width: 90%;
        margin-top: 10px;
    }
}