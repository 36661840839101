.login_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login_box {
  width: 30vw;
  max-width: 400px;
  height: 400px;
  background-color: #f5f5f5;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  padding: 20px;
}

.form_container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.login_text {
  font-size: 35px;
}

.input_field {
  font-size: 18px;
  padding: 10px;
  background-color: #c2c2c2;
  color: black;
  border: none;
  outline: none;
  box-shadow: none;
  width: 90%;
  margin: 8px;
}

.btn_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.signin_button {
  width: 50%;
  padding: 8px;
  font-size: 18px;
  border: none;
  background-color: rgb(153, 51, 153);
  color: white;
  border-radius: 3px;
  margin-top: 10px;
  cursor: pointer;
}

.signin_button:hover {
  background-color: rgb(139, 21, 139);
}

.signup_redirect_wrapper {
  margin-top: 15px;
}

@media only screen and (max-width: 1080px) {
  .login_box {
    width: 50vw;
  }

  .login_text {
    font-size: 28px;
  }
}

@media only screen and (max-width: 650px) {
  .login_box {
    width: 70vw;
  }

  .login_text {
    font-size: 25px;
  }

  .input_field {
    font-size: 16px;
  }
}