.tablerow {
    height: 7vh;
    padding: 20px;
    background-color: #5F2498;
    color: white;
    cursor: pointer;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
}

.page_analytics_heading {
    font-size: 24px !important;
    font-weight: 400 !important;
    text-transform: lowercase !important;
    margin-top: 15px !important;
    margin-bottom: 30px !important;
}

.left_container {
    display: flex;
    flex-direction: column;
    width: 75%;
    padding-right: 15px;
}

.right_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 28%;
    margin-top: 20px;
}

.breadcrumb_container {
	font-size: 20px ;
    font-weight: 100 ;
	color: black !important;
    width: fit-content !important;
    padding-right: 10px;
	margin-left: 20px;
    display: flex;
    margin-bottom: 30px;
    background-color: white;
}

.bc_item {
	font-size: 20px !important;
    font-weight: 100 !important;
	color: black !important;
}

.active_bc_item {
	font-size: 20px !important;
	font-weight: 100 !important;
	color: black !important;
	padding-left: 18px;
	padding-right: 8px;
}

.wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.category_container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
}

.table_data {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
}

.category_table, .severity_table {
    background-color: white;
    border-color: lightgray;
    width: 100%;
}

.severity_table button {
    width: 100%;
    text-decoration: none;
    border: none;
    background-color: white;
}

.severity_table_container {
    margin-bottom: 20px;
}

.category_table td {
    padding: 10px;
    text-transform: capitalize;
}

.category_table th {
    padding: 10px;
}

.filter_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.filter_label {
    font-size: 17px;
}

.label_container {
    display: flex;
    align-items: center;
}

.category_dropdown {
    padding: 8px;
}

.category_dropdown option {
    padding: 10px;
}

.violation_heading {
    font-size: 22px !important;
    text-transform: capitalize;
}

.score_heading {
    font-size: 22px !important;
    text-transform: capitalize;
}

.speedometer_container {
    /* margin: 5px; */
    width: 57%;
    /* min-width: 230px; */
    margin-top: 25px;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 1300px) {
    .container {
        flex-direction: column-reverse;
    }

    .left_container {
        height: 55%;
        width: 95%;
    }

    .right_container {
        height: 40%;
        width: 90%;
    }

    .right_container {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 0px;
    }

    .wrapper {
        width: 45%;
    }

    .category_container {
        width: 45%;
    }

}

@media only screen and (max-width: 1230px) {
    .score_heading {
        font-size: 18px;
    }
}