.audit_form_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .audit_form_box {
    width: 40%;
    height: 30%;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    background-color: #f5f5f5;
    display: flex;
  }
  
  .form_group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .form_group label {
    font-size: 16px;
    margin-bottom: 0px !important;
  }
  
  .audit_form_box h2 {
    font-size: 24px !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
  }
  
  .input_field {
    font-size: 18px;
    padding: 10px;
    background-color: #c2c2c2;
    color: black;
    border: none;
    outline: none;
    box-shadow: none;
    width: 75%;
    margin: 8px;
  }
  
  .btn_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
  }
  
  .audit_button {
    width: 50%;
    padding: 8px;
    font-size: 18px;
    border: none;
    background-color: rgb(153, 51, 153);
    color: white;
    border-radius: 3px;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .audit_button:hover {
    background-color: rgb(139, 21, 139);
  }